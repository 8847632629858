import React from 'react';

const NewsletterSvg = () => {
    const style = {
        enableBackground: 'new 0 0 128 128',
        width: '100%',
        height: '100%'
    }
    return (
        <svg viewBox="0 0 128 128" style={style} xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="m19.146 19.308 3.642 4.884h1.628v-7.558h-1.628v4.885l-3.642-4.885h-1.617v7.558h1.617z"/>
                <path d="m31.798 22.729h-4.193v-1.661h3.796v-1.464h-3.796v-1.506h4.193v-1.464h-5.82v7.558h5.82z"/>
                <path
                    d="m36.428 24.192 1.793-5.192 1.782 5.192h1.629l2.288-7.558h-1.76l-1.397 5.072-1.673-5.072h-1.76l-1.672 5.072-1.397-5.072h-1.761l2.289 7.558z"/>
                <path
                    d="m47.812 22.949a3.73 3.73 0 0 1 -2.486-.99l-.935 1.111a4.854 4.854 0 0 0 3.355 1.254c1.849 0 2.938-.957 2.938-2.4 0-1.4-1.079-1.881-2.706-2.244-1.166-.265-1.618-.44-1.618-.991 0-.45.418-.814 1.111-.814a3.508 3.508 0 0 1 2.157.836l.847-1.166a4.368 4.368 0 0 0 -2.959-1.045c-1.651 0-2.8.957-2.8 2.354 0 1.464 1.133 1.915 2.728 2.278 1.155.264 1.595.429 1.595.968.005.475-.413.849-1.227.849z"/>
                <path d="m57.185 22.729h-3.763v-6.095h-1.628v7.558h5.391z"/>
                <path d="m64.049 22.729h-4.193v-1.661h3.796v-1.464h-3.796v-1.506h4.193v-1.464h-5.82v7.558h5.82z"/>
                <path d="m67.237 24.192h1.629v-6.094h2.354v-1.464h-6.336v1.464h2.353z"/>
                <path d="m74.267 24.192h1.629v-6.094h2.353v-1.464h-6.336v1.464h2.354z"/>
                <path d="m85.158 22.729h-4.192v-1.661h3.796v-1.464h-3.796v-1.506h4.192v-1.464h-5.82v7.558h5.82z"/>
                <path
                    d="m88.006 21.739h1.2l1.728 2.453h1.846l-1.859-2.64a2.46 2.46 0 0 0 1.717-2.365 2.62 2.62 0 0 0 -2.85-2.553h-3.4v7.558h1.617zm0-3.619h1.606c.8 0 1.354.418 1.354 1.067s-.551 1.067-1.354 1.067h-1.606z"/>
                <path
                    d="m121.5 15.583h-15.94v-9.083a1.751 1.751 0 0 0 -1.75-1.75h-97.31a1.75 1.75 0 0 0 -1.75 1.75v103a13.765 13.765 0 0 0 13.75 13.75h94.67a1.712 1.712 0 0 0 .381-.045 10.608 10.608 0 0 0 9.7-10.552v-95.32a1.75 1.75 0 0 0 -1.751-1.75zm-113.25 93.917v-101.25h93.81v104.4a10.542 10.542 0 0 0 2.75 7.1h-86.31a10.261 10.261 0 0 1 -10.25-10.25zm111.5 3.153a7.1 7.1 0 1 1 -14.193 0v-93.57h14.193z"/>
                <path
                    d="m15.5 58.083h41.333a1.749 1.749 0 0 0 1.75-1.75v-23.333a1.75 1.75 0 0 0 -1.75-1.75h-41.333a1.75 1.75 0 0 0 -1.75 1.75v23.333a1.749 1.749 0 0 0 1.75 1.75zm1.75-23.333h37.833v19.833h-37.833z"/>
                <path d="m94.807 35.75h-25.5a1.75 1.75 0 0 0 0 3.5h25.5a1.75 1.75 0 1 0 0-3.5z"/>
                <path d="m94.807 44.719h-25.5a1.75 1.75 0 0 0 0 3.5h25.5a1.75 1.75 0 0 0 0-3.5z"/>
                <path d="m94.807 53.688h-25.5a1.75 1.75 0 0 0 0 3.5h25.5a1.75 1.75 0 0 0 0-3.5z"/>
                <path d="m94.807 62.657h-79.307a1.75 1.75 0 0 0 0 3.5h79.307a1.75 1.75 0 0 0 0-3.5z"/>
                <path d="m94.807 71.625h-79.307a1.75 1.75 0 0 0 0 3.5h79.307a1.75 1.75 0 1 0 0-3.5z"/>
                <path d="m94.807 80.6h-79.307a1.75 1.75 0 0 0 0 3.5h79.307a1.75 1.75 0 0 0 0-3.5z"/>
                <path d="m94.807 89.563h-79.307a1.75 1.75 0 0 0 0 3.5h79.307a1.75 1.75 0 0 0 0-3.5z"/>
                <path d="m94.807 98.532h-79.307a1.75 1.75 0 0 0 0 3.5h79.307a1.75 1.75 0 0 0 0-3.5z"/>
                <path d="m94.807 107.5h-79.307a1.75 1.75 0 0 0 0 3.5h79.307a1.75 1.75 0 0 0 0-3.5z"/>
            </g>
        </svg>

    )
}

export default NewsletterSvg;
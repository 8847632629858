import React, {useState} from "react";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import axios from "axios";
import {api} from "../../../../config";

import style from './index.module.sass'
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {CreateOrder} from "../../../../redux/order/actions";
import {SetAlert} from "../../../../redux/alerts/actions";
import {ValidateCard} from "../../../../services/validators";

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#7E7E7E",
            color: "green",
            fontWeight: 400,
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#fce883"
            },
            "::placeholder": {
                color: "#7E7E7E"
            }
        },
        invalid: {
            color: "#b53612"
        },
    }
};

const PaymentComponent = () => {
    const [cardNumberError, setCardNumberError] = useState({
        show: false,
        validated: false,
        message: 'Your card is invalid'
    })

    const [cardExpirationError, setCardExpirationError] = useState({
        show: false,
        validated: false,
        message: 'Your date is invalid'
    })

    const [cardCVCError, setCardCVCError] = useState({
        show: false,
        validated: false,
        message: 'Your cvc is invalid'
    })

    const elements = useElements();
    const stripe = useStripe();
    const billingAddress = JSON.parse(localStorage.getItem('billingAddress'))
    const cart = useSelector(state => state.cart)
    const history = useHistory()
    const dispatch = useDispatch()

    const {config} = useSelector(state => state)


    const initialFocus = {
        'active': false,
        'element': ''
    }

    const [focus, setFocus] = useState(initialFocus)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async e => {
        e.preventDefault();

        setLoading(true)

        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
                'name': `${billingAddress.firstName} ${billingAddress.lastName}`,
                'email': billingAddress.email,
                'address': {
                    'city': billingAddress.city,
                    'line1': billingAddress.address,
                    'postal_code': billingAddress.zip
                }
            }
        });
        if (payload.error) {
            document.getElementById('cardForm').scrollIntoView()
            dispatch(SetAlert({
                success: false,
                message: payload.error.message
            }))
        } else {
            const {data} = await axios.post(`${api}/payment/`, {
                amount: cart.data.total_price
            })

            const clientSecret = data.data

            const {paymentIntent, error} = await stripe.confirmCardPayment(clientSecret, {
                payment_method: payload.paymentMethod.id
            });

            if (error) {
                document.getElementById('cardForm').scrollIntoView()
                dispatch(SetAlert({
                    success: false,
                    message: error.message
                }))
            } else {
                let billingAddressData = localStorage.getItem('billingAddress') || "{}"
                let billingAddressDataJson = {}
                if (billingAddressData !== JSON.stringify({})) {
                    billingAddressDataJson = JSON.parse(billingAddressData)
                }

                if (paymentIntent.status === 'succeeded') {
                    const orderData = {
                        products: cart.data.products,
                        billing_address: billingAddressDataJson,
                        total_products_price: cart.data.total_products_price,
                        shipping_price: cart.data.shipping,
                        total_price: cart.data.total_price,
                        currency: config.data.payment.currency,
                        payment: {
                            card: true,
                            payment_id: paymentIntent.id
                        }
                    }

                    dispatch(CreateOrder(orderData, history))
                }
            }
        }
        setLoading(false)

    };

    return (
        <form id="cardForm" className={style.box} onSubmit={handleSubmit}>
            <div className={style.boxTitle}>
                <h1>Payment</h1>
            </div>

            <div className={style.boxItem}>
                <label className="label">
                    Card number
                </label>
                <div>
                    <CardNumberElement
                        options={CARD_OPTIONS}
                        className={` ${style.cardInput} ${cardNumberError.validated ? cardNumberError.show ? 'error' : 'success' : null}`}
                        onChange={data => {
                            const response = ValidateCard(data)
                            if (!response.pass) {
                                setCardNumberError({
                                    show: true,
                                    validated: true,
                                    message: response.message
                                })
                            } else {
                                setCardNumberError({
                                    ...cardNumberError,
                                    validated: true,
                                    show: false,
                                })
                            }
                        }}
                    />
                    {
                        cardNumberError.show && <span className="labelError">{cardNumberError.message}</span>
                    }
                </div>
            </div>

            <div className={style.boxGroup}>
                <div className={style.boxItem}>
                    <label className="label">
                        Expiration date
                    </label>
                    <div className={`input ${focus.active && focus.element === 'c_exp' && style.boxBorder}`}>
                        <CardExpiryElement
                            options={CARD_OPTIONS}
                            className={` ${style.cardInput} ${cardExpirationError.validated ? cardExpirationError.show ? 'error' : 'success' : null}`}
                            onChange={data => {
                                const response = ValidateCard(data)
                                if (!response.pass) {
                                    setCardExpirationError({
                                        show: true,
                                        validated: true,
                                        message: response.message
                                    })
                                } else {
                                    setCardExpirationError({
                                        ...cardExpirationError,
                                        validated: true,
                                        show: false,
                                    })
                                }
                            }}
                        />
                        {
                            cardExpirationError.show &&
                            <span className="labelError">{cardExpirationError.message}</span>
                        }
                    </div>
                </div>

                <div className={style.boxItem}>
                    <label className="label">
                        CVC
                    </label>
                    <div className={`input ${focus.active && focus.element === 'c_cvc' && style.boxBorder}`}>
                        <CardCvcElement
                            options={CARD_OPTIONS}
                            className={` ${style.cardInput} ${cardCVCError.validated ? cardCVCError.show ? 'error' : 'success' : null}`}
                            onChange={data => {
                                const response = ValidateCard(data)
                                if (!response.pass) {
                                    setCardCVCError({
                                        show: true,
                                        validated: true,
                                        message: response.message
                                    })
                                } else {
                                    setCardCVCError({
                                        ...cardCVCError,
                                        validated: true,
                                        show: false,
                                    })
                                }
                            }}
                        />
                        {
                            cardCVCError.show &&
                            <span className="labelError">{cardCVCError.message}</span>
                        }
                    </div>
                </div>

            </div>

            <div className={style.boxButton}>
                <button className="button button--full-red" type="submit" disabled={!stripe}>
                    {loading ? 'Submitting ...' : 'Submit Order'}
                </button>
            </div>

        </form>
    );
};

export default PaymentComponent
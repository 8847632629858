import React from 'react';

const NotFoundSvg = () => {
    const style = {
        enableBackground: 'new 0 0 60 48',
        width: '100%',
        height: '100%'
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="512" style={style}>
            <g fill="none" fillRule="evenodd">
                <g id="037---404-Page" fill="rgb(0,0,0)" fillRule="nonzero">
                    <path id="Shape"
                          d="m27 6h6c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1h-6c-.5522847 0-1 .44771525-1 1s.4477153 1 1 1z"/>
                    <path id="Shape"
                          d="m44 6h11c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1h-11c-.5522847 0-1 .44771525-1 1s.4477153 1 1 1z"/>
                    <path id="Shape"
                          d="m55 0h-50c-2.76005315.00330612-4.99669388 2.23994685-5 5v38c.00330612 2.7600532 2.23994685 4.9966939 5 5h50c2.7600532-.0033061 4.9966939-2.2399468 5-5v-38c-.0033061-2.76005315-2.2399468-4.99669388-5-5zm-50 2h50c1.6568542 0 3 1.34314575 3 3v3h-56v-3c0-1.65685425 1.34314575-3 3-3zm50 44h-50c-1.65685425 0-3-1.3431458-3-3v-33h56v33c0 1.6568542-1.3431458 3-3 3z"/>
                    <circle id="Oval" cx="5" cy="5" r="1"/>
                    <circle id="Oval" cx="9" cy="5" r="1"/>
                    <circle id="Oval" cx="13" cy="5" r="1"/>
                    <path id="Shape"
                          d="m18 32c0 .5522847.4477153 1 1 1s1-.4477153 1-1v-2h2c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1h-2v-2c0-.5522847-.4477153-1-1-1s-1 .4477153-1 1v2h-5.211l7.063-11.476c.2539327-.4666582.0986357-1.0504199-.3536085-1.3292152-.4522443-.2787953-1.0435494-.1552918-1.3463915.2812152l-7.066 11.477c-.3791731.6171256-.3950579 1.3910301-.041527 2.0231948.3535309.6321646 1.0212231 1.0237866 1.745527 1.0238052h5.21z"/>
                    <path id="Shape"
                          d="m48 28h-2v-2c0-.5522847-.4477153-1-1-1s-1 .4477153-1 1v2h-5.211l7.063-11.476c.2110153-.3041508.2367456-.6999905.0668803-1.0288992-.1698654-.3289087-.5075332-.537071-.8776942-.5410726s-.71225.1968122-.8891861.5219718l-7.066 11.477c-.3791731.6171256-.3950579 1.3910301-.041527 2.0231948.3535309.6321646 1.0212231 1.0237866 1.745527 1.0238052h5.21v2c0 .5522847.4477153 1 1 1s1-.4477153 1-1v-2h2c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"/>
                    <path id="Shape"
                          d="m29 33h2c2.209139 0 4-1.790861 4-4v-10c0-2.209139-1.790861-4-4-4h-2c-2.209139 0-4 1.790861-4 4v10c0 2.209139 1.790861 4 4 4zm-2-14c0-1.1045695.8954305-2 2-2h2c1.1045695 0 2 .8954305 2 2v10c0 1.1045695-.8954305 2-2 2h-2c-1.1045695 0-2-.8954305-2-2z"/>
                    <path id="Shape"
                          d="m42 38h-24c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h24c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"/>
                    <path id="Shape"
                          d="m33 42h-6c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h6c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"/>
                </g>
            </g>
        </svg>
    )
}

export default NotFoundSvg;
// All validation will return a boolean value

// The validate text is a standard input validation
export function ValidateText(text) {
    return text.trim().length > 0 && text
}

export function ValidateEmail(mail) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)
}

export function ValidateCard(number) {
    let pass = true
    let message = ''
    if (number['empty']) {
        pass = false
        if (number['elementType'] === 'cardNumber') message = 'Card number is required'
        if (number['elementType'] === 'cardExpiry') message = 'Card expiry is required'
        if (number['elementType'] === 'cardCvc') message = 'Card cvc is required'
    }
    if (number['error']) {
        pass = false
        message = number['error']['message']
    }

    return {
        pass, message
    }
}
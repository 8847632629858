import React from 'react'

const style = {
    fontSize: '1.5rem',
    padding: '0 1rem'
}

const NotFoundComponent = () => (
    <div style={style}>
        <p>Not found</p>
    </div>
)


export default NotFoundComponent
import React from "react";
import style from './index.module.sass'

const PaymentOptions = ({paymentOption, setPaymentOption}) => {
    return (
        <div className={style.box}>
            <div className={style.boxTitle}>
                <h1>Payment options</h1>
            </div>
            <div className={style.boxOptions}>

                <div
                    className={paymentOption.value === 1 ? 'success' : null}
                    onClick={() => setPaymentOption({
                        option: 'online',
                        value: 1
                    })}
                >
                    <p className="hideOverText">Online payment - Credit or Debit card</p>
                </div>
                <div
                    className={paymentOption.value === 2 ? 'success' : null}
                    onClick={() => setPaymentOption({
                        option: 'on_delivery',
                        value: 2
                    })}
                >
                    <p className="hideOverText">Payment on delivery</p>
                </div>

            </div>
        </div>
    )
}

export default PaymentOptions
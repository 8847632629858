import React from "react";

const CartSvg = () => {
    const style = {
        width: '100%',
        height: '100%',
        enableBackground: 'new 0 0 469.333 469.333',
        fill: 'inherit',
    }

    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 469.333 469.333" style={style}
             xmlSpace="preserve">
            <g>
                <path d="M434.979,42.667H85.333v3.061l-4.375-14.999C75.687,12.635,58.844,0,40,0H10.667C4.771,0,0,4.771,0,10.667
				s4.771,10.667,10.667,10.667H40c9.427,0,17.844,6.313,20.479,15.365l66.396,227.635l-34.021,42.521
				c-4.854,6.073-7.521,13.688-7.521,21.458c0,18.948,15.406,34.354,34.354,34.354H416c5.896,0,10.667-4.771,10.667-10.667
				c0-5.896-4.771-10.667-10.667-10.667H119.687c-7.177,0-13.021-5.844-13.021-13.021c0-2.948,1.01-5.844,2.854-8.135l34.279-42.844
				h209.221c16.448,0,31.604-9.615,38.615-24.5l74.438-158.177c2.135-4.552,3.26-9.604,3.26-14.615v-3.021
				C469.333,58.073,453.927,42.667,434.979,42.667z M448,80.042c0,1.906-0.427,3.823-1.24,5.542L372.333,243.75
				c-3.51,7.438-11.083,12.25-19.313,12.25H146.667L90.663,64h344.316C442.156,64,448,69.844,448,77.021V80.042z"/>
                <path d="M128,384c-23.531,0-42.667,19.135-42.667,42.667s19.135,42.667,42.667,42.667s42.667-19.135,42.667-42.667
				S151.531,384,128,384z M128,448c-11.76,0-21.333-9.573-21.333-21.333c0-11.76,9.573-21.333,21.333-21.333
				c11.76,0,21.333,9.573,21.333,21.333C149.333,438.427,139.76,448,128,448z"/>
                <path d="M384,384c-23.531,0-42.667,19.135-42.667,42.667s19.135,42.667,42.667,42.667s42.667-19.135,42.667-42.667
				S407.531,384,384,384z M384,448c-11.76,0-21.333-9.573-21.333-21.333c0-11.76,9.573-21.333,21.333-21.333
				c11.76,0,21.333,9.573,21.333,21.333C405.333,438.427,395.76,448,384,448z"/>
            </g>
        </svg>
    )
}

export default CartSvg